import {navigate} from 'gatsby';
import React, {useEffect} from 'react';

export default function Home() {
  useEffect(() => {
    console.info('navigating to app');
    navigate('/app');
  }, []);
  return <div>Index</div>;
}
